<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :expand-on-hover="getHasCollapsibleSidebar"
      rail
      app
    >
      <v-list dense class="sidebar-menu pt-0">
        <div class="sidebar-menu__logo">
          <v-toolbar-title class="top-app-logo hidden-md-and-down">
            <router-link :to="getDefaultPageName">
              <v-img contain max-height="38px" max-width="160px" :src="logoSrc" />
            </router-link>
          </v-toolbar-title>
        </div>
        <template v-for="(item, index) in sidebarItems">
          <v-list-group v-if="item.items" :key="index">
            <template #activator>
              <v-list-item-action class="mr-2">
                <v-icon v-text="`$${item.name}`" />
              </v-list-item-action>
              <v-list-item-title>
                {{ t(`menu.${item.text}`) }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-for="subItem in item.items"
              :key="`${index}-${subItem.text}`"
              link
              :title="subItem.text && subItem.text.length > 25 ? subItem.text : null"
              :href="subItem.href || null"
              :data-test="`${subItem.name}MenuLink`"
              :to="subItem.href ? null : { name: subItem.name, hash: subItem.hash || '' }"
              style="min-height: 50px"
            >
              <v-list-item-action class="mr-2"> </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ t(`menu.${subItem.text}`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <template v-else>
            <v-list-item
              :key="`${index}-${item.text}`"
              link
              :title="item.text && item.text.length > 25 ? item.text : null"
              :href="item.href || null"
              :data-test="`${item.name}MenuLink`"
              :to="item.href ? null : { name: item.name, hash: item.hash || '' }"
              style="min-height: 50px"
            >
              <v-list-item-action class="mr-2">
                <v-icon v-text="`$${item.name}`" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ t(`menu.${item.text}`) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
        <div v-if="getHasCollapsibleSidebar" class="v-list-item sidebar-menu__chevron">
          <v-icon color="black"> mdi-chevron-double-right </v-icon>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :class="hasCollapsibleSidebarClass" app flat clipped-left color="#ffffff">
      <v-app-bar-nav-icon
        v-show="vuetify.breakpoint.mdAndDown"
        class="ml-1"
        @click.stop="drawer = !drawer"
      />
      <div class="top-breadcrumb d-inline-flex ml-0 ml-sm-4">
        <v-icon class="mr-2" v-text="`$${currentMenu.name}`" />
        {{ t(`menu.${currentMenu.name}`) }}
      </div>
      <v-spacer />
      <div class="top-panel">
        <v-btn
          text
          data-test="languageChangeButton"
          class="px-1 px-sm-5"
          @click="languageOverlay = !languageOverlay"
        >
          <v-img
            :src="currentLanguage.icon"
            max-height="16px"
            max-width="16px"
            class="mr-2"
          />
          <span v-show="breakpoints">
            {{ currentLanguage.title }}
          </span>
          <v-icon right dark> mdi-chevron-down </v-icon>
        </v-btn>
        <v-divider inset vertical />

        <v-menu>
          <template #activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              class="px-1 px-sm-5"
              data-test="navbarAccountMenu"
              v-on="on"
              @click="accountOverlay = true"
            >
              <div>
                <div v-show="breakpoints">
                  {{ debtorInfo }}
                </div>
                <div
                  v-if="getServicingDebtor && getServicingDebtor.name"
                  class="service-account"
                >
                  <v-icon style="font-size: 17px"> mdi-account-arrow-right </v-icon>
                  {{ getServicingDebtor.name }}
                </div>
              </div>
              <v-icon v-show="!breakpoints" right dark> mdi-account </v-icon>
              <v-icon right dark> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
        </v-menu>
      </div>
    </v-app-bar>
    <v-main>
      <overlay
        :show-overlay="languageOverlay"
        style="z-index: 6"
        @closeOverlay="languageOverlay = false"
      >
        <v-list light class="pa-0" data-test="languageListOverlay">
          <v-list-item-group>
            <v-list-item
              v-for="(language, languageIndex) in languages"
              :key="languageIndex"
              :disabled="language.disabled"
              style="min-height: 70px"
              @change="changeLanguage(languageIndex)"
            >
              <v-list-item-icon class="mr-2 mt-auto mb-auto">
                <v-img :src="language.icon" max-width="18px" max-height="18px" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ language.title }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon color="black"> mdi-chevron-right </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </overlay>
      <router-view />
    </v-main>
    <account-overlay-component
      :show-overlay="accountOverlay"
      @closeAccountOverlay="accountOverlay = false"
    />
  </v-app>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { localize } from 'vee-validate'
import { useRoute } from 'vue-router/composables'
import { useNamespacedGetters, useNamespacedActions } from 'vuex-composition-helpers'
import { includes, reject } from 'lodash-es'
import { useI18n } from 'vue-i18n-composable'
import { checkVersion } from '@/utils/versionCheck'

//components
import Overlay from '@/components/Overlays/Overlay'
import AccountOverlayComponent from '@/components/Overlays/AccountOverlay'

//composables
import useVideoList from '@/composables/Components/Cards/useVideoList'
import useDownloadList from '@/composables/Components/Cards/useDownloadList'
import { useVuetify } from '@/composables/useVuetify'

//constants
import {
  SHOP_SETTINGS_DEFAULT,
  SHOP_SETTINGS_TEST_PROJ,
  DEFAULT_SHOP,
  NEW_PROJ_SHOP
} from '@/constants'

const route = useRoute()
const { locale, t } = useI18n()
const vuetify = useVuetify()
const { hasVideos } = useVideoList()
const { downloadCategories } = useDownloadList()

const {
  getDebtor,
  getServicingDebtor,
  getEasyorderLogo,
  getHasCustomerFabrics,
  getSystemSettings,
  getShopName,
  getDefaultPageName,
  getHasCollapsibleSidebar,
  getSystemLanguage
} = useNamespacedGetters('user', [
  'getDebtor',
  'getServicingDebtor',
  'getEasyorderLogo',
  'getHasCustomerFabrics',
  'getSystemSettings',
  'getShopName',
  'getDefaultPageName',
  'getHasCollapsibleSidebar',
  'getSystemLanguage'
])

const { updateSystemSettings, loadSystemSettings } = useNamespacedActions('user', [
  'updateSystemSettings',
  'loadSystemSettings'
])

const serviceMenuItem = {
  text: 'service',
  name: 'service',
  items: []
}

downloadCategories.value.forEach((category) => {
  serviceMenuItem.items.push({
    text: `service-${category}`,
    name: 'service',
    hash: `#service-${category}`
  })
})

if (hasVideos.value) {
  serviceMenuItem.items.push({
    text: `service-videos`,
    name: 'service',
    hash: `#service-videos`
  })
}

// `name` is the name of the route in vue-router,
// `href` parameter has more priority and works as default web links
const baseSidebarItems = [
  {
    text: 'dashboard',
    name: 'dashboard'
  },
  {
    text: 'products',
    name: 'products'
  },
  {
    text: 'offer',
    name: 'offer'
  },
  {
    text: 'order',
    name: 'order'
  },
  {
    text: 'track',
    name: 'track'
  },
  {
    text: 'sampleservice',
    name: 'sampleservice'
  },
  {
    text: 'customer-fabrics',
    name: 'customer-fabrics'
  },
  {
    text: 'settings',
    name: 'settings'
  },
  ...(serviceMenuItem.items.length ? [serviceMenuItem] : []),

  {
    text: 'help',
    name: 'help'
  },
  {
    text: 'release-notes',
    name: 'release-notes'
  },
  {
    text: 'fabric-collection-images',
    name: 'fabric-collection-images',
    href: 'https://www.dropbox.com/scl/fo/83uuatut7f7xarskdh5k1/h?rlkey=wrn1jemjlteu3o9jffgfnvgmy&dl=0'
  }
]

//data
const languageOverlay = ref(false)
const accountOverlay = ref(false)
const currentLanguage = ref({})
const drawer = ref(null)
const languages = [
  {
    title: 'Nederlands',
    shortCode: 'nl',
    icon: require('../../assets/netherlands.png'),
    disabled: false
  },
  {
    title: 'English',
    shortCode: 'en-au',
    icon: require('../../assets/united-kingdom.png'),
    disabled: false
  },
  {
    title: 'Polskie',
    shortCode: 'pl',
    icon: require('../../assets/poland.png'),
    disabled: false
  },
  {
    title: 'Français',
    shortCode: 'fr',
    icon: require('../../assets/france.png'),
    disabled: false
  },
  {
    title: 'Deutsch',
    shortCode: 'de',
    icon: require('../../assets/germany.png'),
    disabled: false
  },
  {
    title: 'Lietuvių',
    shortCode: 'lt',
    icon: require('../../assets/lithuania.png'),
    disabled: false
  }
]

//computed
const currentMenu = computed(() => {
  return (
    (route.meta && route.meta.currentMenu) || {
      text: 'unknown',
      name: 'unknown'
    }
  )
})

const breakpoints = computed(() => {
  return vuetify.breakpoint.width >= 960
})

const debtorInfo = computed(() => {
  return Object.keys(getDebtor.value).length
    ? `${getDebtor.value.name} ${getDebtor.value.city}`
    : 'Unknown debtor'
})

const logoSrc = computed(() => {
  return getEasyorderLogo.value ? getEasyorderLogo.value : getProjectProps.value.logo
})

const getProjectProps = computed(() => {
  const getShopMapping = {
    [DEFAULT_SHOP]: SHOP_SETTINGS_DEFAULT,
    [NEW_PROJ_SHOP]: SHOP_SETTINGS_TEST_PROJ
  }
  const currentShop =
    Object.keys(getShopMapping).indexOf(getShopName.value) === -1
      ? 'default' // fallback
      : getShopName.value
  return getShopMapping[currentShop]
})

const hasCollapsibleSidebarClass = computed(() => {
  return getHasCollapsibleSidebar.value ? 'collapsibleSidebar' : 'classicSidebar'
})

const sidebarItems = computed(() => {
  let ignoreItems = []
  if (!getSystemSettings.value.show_sidebar_track_button) ignoreItems.push('track')
  if (!getSystemSettings.value.show_sidebar_customer_fabrics_button)
    ignoreItems.push('customer-fabrics')
  if (!getSystemSettings.value.show_sidebar_service_account_button)
    ignoreItems.push('service')
  if (!getHasCustomerFabrics.value) ignoreItems.push('customer-fabrics')
  if (!getSystemSettings.value.show_sidebar_release_notes_button)
    ignoreItems.push('release-notes')
  if (!getSystemSettings.value.show_sample_service_buttons)
    ignoreItems.push('sampleservice')
  if (!getSystemSettings.value.show_sidebar_settings_button) ignoreItems.push('settings')
  if (getSystemSettings.value.show_only_tracking_page) {
    return [
      {
        text: 'track',
        name: 'track'
      }
    ]
  }
  if (!['nl', 'pl'].includes(locale.value)) {
    ignoreItems.push('fabric-collection-images')
  }
  return reject(
    baseSidebarItems.map((item) => {
      if (item.items) {
        if (includes(ignoreItems, item.text)) {
          return
        }
        return {
          ...item,
          items: reject(item.items, (subItem) => includes(ignoreItems, subItem.text))
        }
      }
      if (includes(ignoreItems, item.text)) {
        return
      }
      return item
    }),
    (item) => typeof item === 'undefined'
  )
})

//methods
const changeLanguage = async (value) => {
  currentLanguage.value = { ...languages[value] }
  locale.value = currentLanguage.value.shortCode
  const requestData = {
    preferred_language: currentLanguage.value.shortCode
  }
  localize(currentLanguage.value.shortCode)
  await updateSystemSettings(requestData)
  languageOverlay.value = false
}

//watch
watch(
  currentLanguage,
  (language) => {
    if (language.shortCode === 'nl' && !getSystemSettings.value.show_only_tracking_page) {
      const chat = document.querySelector('#trengo-web-widget')
      const chatIframe = document.querySelector('#trengo-widget')
      if (chat && chatIframe) {
        // chat is still present, make it visible
        chat.style.visibility = 'visible'
        chatIframe.style.visibility = 'visible'
      } else {
        // no chat present, initialize chat
        window.Trengo = window.Trengo || {}
        window.Trengo.key = 'RC3osyZO8FlRTsMzKRNJ'
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://static.widget.trengo.eu/embed.js'
        document.getElementsByTagName('head')[0].appendChild(script)
      }
    } else {
      // hide chat
      const chat = document.querySelector('#trengo-web-widget')
      const chatIframe = document.querySelector('#trengo-widget')
      if (chat) {
        chat.style.visibility = 'hidden'
      }
      if (chatIframe) {
        chatIframe.style.visibility = 'hidden'
      }
    }
  },
  { immediate: true }
)

onMounted(() => {
  loadSystemSettings()
  const language = getSystemLanguage.value || 'nl'
  const lang = languages.find((item) => item.shortCode === language)
  locale.value = language
  currentLanguage.value = { ...lang }
  document.documentElement.style.setProperty(
    '--general-color',
    getProjectProps.value.primaryColor
  )
  checkVersion()
})
</script>

<style lang="scss">
:root {
  --generalColor: #b6735c;
}

.v-toolbar__content {
  padding: 0 16px 0 0 !important;
  box-shadow: 2px 2px 8px rgba(100, 100, 100, 0.15);
  .top-breadcrumb {
    display: inline-block !important;
    min-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    path {
      background-color: var(--general-color) !important;
      fill: var(--general-color);
    }
  }
  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 1px;
  }

  .service-account {
    color: gray;
  }
}
.classicSidebar {
  .v-toolbar__content {
    @media (min-width: 1264px) {
      margin-left: 256px;
    }
  }
}

.collapsibleSidebar {
  .v-toolbar__content {
    @media (min-width: 1264px) {
      margin-left: 64px;
    }
  }
}

.top-app-logo {
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  background: #fff;

  a {
    margin: 0 auto;
    padding-right: 6px;
  }

  .app-version {
    margin-left: 0.5rem;
    font-family: Frank Ruhl Libreina, Arial, sans-serif;
    font-weight: 500;
    color: var(--general-color);
  }
}

.top-panel {
  display: flex;
  height: 100%;

  .v-btn {
    height: 100% !important;
    text-transform: none;
    border-radius: 0;
  }
}

.sidebar-menu {
  backface-visibility: hidden;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;

  &__logo {
    overflow: hidden;
    min-height: 64px;
    padding: 0 16px;
    transition: $primary-transition;
    box-shadow: 2px 2px 8px rgba(100, 100, 100, 0.15);

    @media (max-width: 1263px) {
      display: none;
    }
  }

  &.v-list {
    .v-list-item {
      border-left: 5px solid transparent;
      max-height: 50px;
      &--active {
        border-left-color: var(--general-color) !important;

        .v-list-item__title {
          font-weight: bold !important;
        }
      }

      path {
        fill: var(--general-color);
      }
    }
    .v-list {
      &-group {
        &__header {
          min-height: 50px;
          max-height: 50px;
        }
      }
    }
  }
  &__chevron {
    transition: $primary-transition;
    margin-top: auto;
    color: rgba(0, 0, 0, 0.87);
  }
}

.v-navigation-drawer {
  box-shadow: 2px 2px 8px rgba(100, 100, 100, 0.15);
  min-width: 65px;

  &.v-navigation-drawer--is-mouseover {
    .sidebar-menu {
      &__chevron {
        overflow: hidden;
        opacity: 0;
      }
    }
  }

  &--mini-variant {
    .sidebar-menu {
      &__logo {
        padding: 0;
      }
    }
  }

  &__border {
    width: 0 !important;
  }
}
</style>
